<template>
  <div class="animated fadeIn">
		<CTabs  variant="pills" :active-tab="active_tab" @update:activeTab="changeTab" >
			<CTab title="PrintOut Sources"/>
			<CTab title="Create New Source" v-if="$store.getters['can']('create-printout-source')"/>
		</CTabs>
		<hr>
		<div>
	        <router-view @activeTab="emitHomeTab($event)">

	        </router-view>
	    </div>
	</div>
</template>
<script>
export default {
    name:'PrintOutSourceHome',
	data() {
		return {
			active_tab: this.$router.history.current.path.substring(this.$router.history.current.path.lastIndexOf('/') + 1) == 'create' ? 1 : 0,
		}
	},
	methods: {
		changeTab: function(item) {
			this.$router.push(!item ? `/${this.$store.getters.getOrganizationName}/form-management/printout-source` : `/${this.$store.getters.getOrganizationName}/form-management/printout-source/create`)
			this.active_tab = item;
		},
		emitHomeTab: function (value) {
			this.active_tab = value
		}
	}
}
</script>

<style>

</style>